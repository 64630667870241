<template>
    <div v-if="emailSent">
        <p class="text-muted mt-3 text-center">
            An email has been sent. Please check for an email from company and
            click on the included link to reset your password.
        </p>
        <router-link
            to="/login"
            class="btn btn-block btn-primary waves-effect waves-light mt-3"
        >
            Back to login
        </router-link>
    </div>

    <div v-else>
        <p class="text-muted mb-4 mt-3 text-center">
            Enter your email address and we'll send you an email with
            instructions to reset your password.
        </p>
        <form @submit.prevent="tryToSendLink">
            <div class="form-group py-3">
                <label for="email">Email</label>
                <input
                    id="email"
                    v-model="email"
                    :class="{ 'is-invalid': $v.email.$error }"
                    class="form-control"
                    type="email"
                    placeholder="Enter Email"
                />
                <div v-if="emailErrors.length" class="invalid-feedback">
                    {{ emailErrors[0] }}
                </div>
            </div>
            <button type="submit" class="btn btn-primary btn-block mt-3">
                Send Reset Password Link
            </button>
        </form>

        <p class="text-center mt-2">
            Already have account?
            <router-link to="/login">
                Log In
            </router-link>
        </p>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as Sentry from '@sentry/vue';
import { required, email } from 'vuelidate/lib/validators';

export default {
    page: {
        title: 'Forget Password'
    },

    validations: {
        email: { required, email }
    },

    data() {
        return {
            email: '',
            emailSent: false
        };
    },

    computed: {
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.required && errors.push('Email is required.');

            return errors;
        }
    },

    methods: {
        ...mapActions({
            sendResetPasswordLink: 'auth/sendResetPasswordLink'
        }),

        async tryToSendLink() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.sendResetPasswordLink(this.email);

                this.email = '';
                this.emailSent = true;
            } catch (err) {
                console.error(err);

                Sentry.captureException(err);

                this.$toasterError();
            }
        }
    }
};
</script>
